var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("head-layout", { attrs: { "head-title": "我的培训" } }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: {
                  "grid-head-btn": _vm.gridHeadBtn,
                  "search-columns": _vm.searchColumns,
                },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.crudLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "grid-row-detail-click": _vm.rowDetail,
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                  "page-refresh-change": _vm.onLoad,
                  "gird-handle-select-click": _vm.selectionChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "courseCover",
                    fn: function ({ row }) {
                      return [
                        row.courseCover == ""
                          ? _c("img", {
                              staticClass: "courseCoverImg",
                              attrs: {
                                src: require("@/assets/training/trainingDefaultPicture.png"),
                              },
                            })
                          : _c("img", {
                              staticClass: "courseCoverImg",
                              attrs: { src: row.courseCover },
                            }),
                      ]
                    },
                  },
                  {
                    key: "customBtn",
                    fn: function ({ row }) {
                      return [
                        _vm.isTrainingEnded(row.trainingEndTime) &&
                        row.isAccomplish != 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowContinuing(row)
                                  },
                                },
                              },
                              [_vm._v(" 开始培训\n          ")]
                            )
                          : _vm._e(),
                        !_vm.isTrainingEnded(row.trainingEndTime) ||
                        row.isAccomplish == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowDetail(row)
                                  },
                                },
                              },
                              [_vm._v(" 查看\n          ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }