<template>
  <div>
    <el-container>
      <el-main>
        <head-layout
          head-title="我的培训"
        ></head-layout>
        <grid-head-layout
          ref="gridHeadLayout"
          :grid-head-btn="gridHeadBtn"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="crudLoading"
          :data-total="page.total"
          :page="page"
          @grid-row-detail-click="rowDetail"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          @page-refresh-change="onLoad"
          @gird-handle-select-click="selectionChange"
        >
          <template #courseCover="{ row }">
            <img class="courseCoverImg" v-if="row.courseCover == ''" src="@/assets/training/trainingDefaultPicture.png">
            <img class="courseCoverImg" v-else :src="row.courseCover">
          </template>
          <template #customBtn="{row}">
            <el-button
              type="text"
              v-if="isTrainingEnded(row.trainingEndTime)&&
              row.isAccomplish != 1"
              @click="rowContinuing(row)"
            > 开始培训
            </el-button>
            <el-button
              type="text"
              v-if="!isTrainingEnded(row.trainingEndTime)||
              row.isAccomplish == 1"
              @click="rowDetail(row)"
            > 查看
            </el-button>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
  </div>
</template>


<script>
import CommonTree from "@/views/components/com_tree";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {deptChildIds, deptChildTree, treeReport} from "@/api/reportTemplate";
import {getPage} from "@/api/training/trainingEducation";
import {mapGetters} from "vuex";
import {getTrainingRducationSettingTreeTree} from "@/api/training/reEducationSetting";

export default {
  name:"trainingEducation",
  data() {
    return {
      form: {},
      query: {},
      treeReadOnlyFlag: false,
      tableData: [],
      treeLoading: false,
      crudLoading: false,
      dicData: [],
      node: {},
      fullscreen: false,
      showTreeDialog: false,
      classifyId: '',
      showDialog: false,
      formType: '',
      formList: {},
      treeData: [],
      selectionList: [],
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      searchTitle: "title",
      deptId: '',
      defaultCheckedKeys: [],
    }
  },
  computed: {
    ...mapGetters(["permission", 'colorName', 'userInfo']),
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    searchColumns() {
      return [
        {
          label: "",
          prop: "courseName",
          span: 4,
          type: "input",
          placeholder: "请输入名称",
        },
        {
          label: "",
          prop: "status",
          span: 4,
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=education_status",
          dicData: [],
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          type: "select",
          placeholder: "请选择状态",
        },
        {
          label: "",
          prop: "trainingDate",
          span: 4,
          type: "daterange",
          placeholder: "请选择培训日期",
          component: {
            name: 'av-date-picker',
            props: {
              type: 'date',
              format: 'yyyy-MM-dd'
            },
          },
        },
      ];
    },
    gridHeadBtn() {
      return [];
    },
    tableOptions() {
      return {
        menuWidth: 100,
        selection: true,
        linklabel: "courseName",
        column: [
          {
            label: "名称",
            prop: "courseName",
            align: "left",
            overHidden: true,
          },
          {
            label: "课程",
            prop: 'courseCover',
            slot: true,
            width: 120
          },
          {
            label: "课时",
            prop: "totalHours",
            align: "center",
            width: 80,
            overHidden: true,
          },
          {
            label: "状态",
            prop: "status",
            align: "center",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=education_status",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            width: 80,
            dataType: "number",
            overHidden: true,
          },
          {
            label: "标签",
            prop: "label",
            align: "center",
            overHidden: true,
            width: 140,
          },
          {
            label: "达标课时",
            prop: "standardsHours",
            align: "center",
            width: 90,
            overHidden: true,
          },
          {
            label: "完成必修课时",
            prop: "accomplishAccomplish",
            align: "right",
            width: 130,
            overHidden: true,
          },
          {
            label: "是否完成",
            prop: "isAccomplish",
            align: "center",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=exam_status",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            width: 90,
            dataType: "number",
            overHidden: true,
          },
          {
            label: "培训时间",
            prop: "trainingTimeRange",
            align: "center",
            width: 240,
            overHidden: true,
          },
        ],
      };
    },
  },
  components: {
    GridLayout,
    HeadLayout,
    CommonTree
  },
  mounted() {
    this.onLoad(this.page);
  },
  activated(){
    this.onLoad(this.page, this.query);
  },
  methods: {
    isTrainingEnded(trainingEndTime) {
      if (!trainingEndTime) return false;
      const currentTime = new Date();
      currentTime.setHours(0, 0, 0, 0);
      const endTime = new Date(trainingEndTime);
      endTime.setHours(0, 0, 0, 0);
      return currentTime <= endTime;
    },

    rowContinuing(row) {
      this.$router.push({
        path: "/business/safetyenment/train/trainingEducation/form",
        query: {
          id: row.id
        },
      });
    },
    gridHeadSearch(searchForm) {
      this.query = searchForm;
      this.onLoad(this.page, searchForm);
    },
    gridHeadEmpty(searchForm) {
      this.query = {};
      this.onLoad(this.page, searchForm);
    },
    rowDetail(row) {
      this.$router.push({
        path: "/business/safetyenment/train/trainingEducation/view",
        query: {
          id: row.id,
          type: "view"
        },
      });
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
    },
    onLoad(page, params = {}) {
      let cc = {}
      cc.courseName = params.courseName
      cc.status = params.status
      if (params.trainingDate) {
        cc.startTime = params.trainingDate[0];
        cc.endTime = params.trainingDate[1];
      } else {
        params.startTime = null
        params.endTime = null
      }
      this.page = page;
      this.crudLoading = true;
      getPage(
        page.currentPage,
        page.pageSize,
        Object.assign(cc)
      )
        .then((res) => {
          const data = res.data.data;
          this.$refs.gridLayOut.page.total = data.total;
          this.page.total = data.total;
          data.records.forEach(e => {
            if (e.isAccomplish == 2 && !this.isTrainingEnded(e.trainingEndTime)) {
              e.isAccomplish = '3'
            }
          })
          console.log(data.records)
          this.tableData = data.records
          this.crudLoading = false;
          this.selectionClear();
        })
    },
    // 获取组织架构和顶部导航
    getDeptChildTreeData() {
      deptChildTree().then((res) => {
        if (res.data.code == 200) {
          this.treeData.push(res.data.data);
          this.deptId = this.treeData[0].id;
          this.$nextTick(() => {
            this.defaultCheckedKeys.push(this.deptId)
          })
          this.getTopMenu()
        }
      })
    },
    getTopMenu() {
      treeReport(
        this.deptId
        // includeStatus:this.includeStatus
      ).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          this.replaceData(data);
          this.topSidderData = data;
        }
      })
    },
    replaceData(data) {
      data.forEach((item) => {
        if (item.reportVOList && item.reportVOList.length != 0 && item.children) {
          item.children = item.children.concat(item.reportVOList);
        } else if (item.reportVOList && item.reportVOList.length != 0 && item.children == undefined) {
          item.children = new Array();
          item.children = item.children.concat(item.reportVOList);
        } else {
          item.children = new Array();
        }
        if (item.reportName) {
          item.reportCategoryName = item.reportName;
        }
        if (item.reportReviewPath == undefined) {
          item.reportReviewPath = '';
        }
        if (item.children && item.children.length != 0) {
          this.replaceData(item.children);
        }
      })
    },
    getDeptUrl(deptId) {
      if (this.urlPath != '') {
        if (this.includeStatus) {
          deptChildIds(deptId).then((res) => {
            if (res.data.code == 200) {
              this.deptIds = res.data.data;
              this.iframeUrl = this.urlPath + '&type=0' + '&deptId=' + this.deptIds;
            }
          })
        } else {
          this.iframeUrl = this.urlPath + '&type=0' + '&deptId=' + deptId;
        }
      }
    },
    includeDown(e) {
      this.includeStatus = e;
      if (this.includeStatus) {
        this.$loading()
        deptChildIds(this.deptId).then((res) => {
          if (res.data.code == 200) {
            this.deptIds = res.data.data;
            this.iframeUrl = this.urlPath + '&type=0' + '&deptId=' + this.deptIds;
            this.$loading().close()
          }
        })
      } else {
        this.iframeUrl = this.urlPath + '&type=0' + '&deptId=' + this.deptId;
      }
    },
  }
}

</script>
<style scoped lang="scss">
.courseCoverImg {
  width: 80px;
  padding: 5px;
}
</style>
